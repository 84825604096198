<template>
  <div class="SatisfactionSurvey">
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '全程满意度调查',
          desc: '华信互联致力于通过覆盖院内诊疗和院外管理的服务来实现“联结患者和医疗资源”。以“医防融合”为着力点，依托管理平台，为慢性病患者提供全方位、多层次、定制化的全程精细化慢病管理服务。',
        }"
        :img="require('@/assets/ChronicDisease/banner.png')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <isTab current="全程满意度调查" :info="info" />
      <!-- <isCom /> -->
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import isTab from "@/components/ProductTab/tab.vue";
// import isCom from "@/components/ChronicDisease/com.vue";
export default {
  name: "SatisfactionSurvey",
  components: {
    // isCom,
    commonTop,
    isHeader,
    isFooter,
    isTab,
  },
  data() {
    return {
      info: {
        partOne: {
          title: "产品设计理念",
          sub: "动态收集全流程就医反馈 深度挖掘患者满意度数据价值",
          swiper: [
            {
              bg: require("@/assets/ChronicDisease/01.png"),
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 98 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1379_34034)">
                            <path d="M78.9809 45.859V20.8909C78.9809 18.8527 77.4522 16.8145 74.9044 16.8145H61.1465" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M18.8537 17.3242H4.5862C2.54798 17.3242 0.509766 18.8529 0.509766 21.4007V73.8847C0.509766 74.9038 1.01932 75.4134 2.03843 75.4134H71.8474" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M27.5138 7.13281H21.3991C19.8705 7.13281 18.3418 8.15192 18.3418 10.1901V75.4131" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M51.9707 7.13281H58.0854C59.614 7.13281 61.1427 8.15192 61.1427 10.1901V51.9736" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M33.1211 7.13281H46.8791" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M40.2539 0V14.2675" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M29.0449 74.9048V54.5226C29.0449 53.5035 30.064 52.4844 31.0831 52.4844H48.408C49.4271 52.4844 50.4462 53.5035 50.4462 54.5226V74.9048" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M29.0449 44.8398H50.4462" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M69.2988 24.459V31.5927" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M9.68164 24.459V31.5927" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M9.68164 41.7832V49.4265" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M9.68164 59.6172V67.2605" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M89.6773 63.6947L95.7919 57.58L81.5244 43.3125L69.8047 55.0322L73.8811 59.1087L81.5244 51.4654" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M74.8997 49.9367L68.2755 43.3125L54.5176 57.58L75.4093 78.9813L90.6959 64.7138L79.4857 54.0131" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M81.5293 63.6934L86.6248 68.7889" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M77.9551 66.752L83.0506 71.3379" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M74.3926 69.8105L79.4881 74.9061" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1379_34034">
                            <rect width="97.3249" height="80" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            `,
              name: "增强患者粘性",
            },
            {
              bg: require("@/assets/ChronicDisease/02.png"),
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 88 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M59.632 24.2949H27.6074V43.0679H59.632V24.2949Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M35.8887 23.2947V19.4297H51.3488V23.2947" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M39.2012 33.6797H48.0355" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M43.6211 29.2637V38.098" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M54.1113 89.9995V73.4351C72.3322 68.4657 85.5837 51.9013 85.5837 32.5762" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M1.10352 32.5762C1.10352 52.4535 14.3551 69.0179 32.5759 73.4351V89.9995" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M72.8841 22.6387C73.9884 25.9516 74.5406 28.7123 74.5406 32.5773C74.5406 45.2767 66.8105 56.3197 55.7676 61.289" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M20.4297 11.5943C25.9512 5.52069 34.2334 1.10352 43.6199 1.10352C50.2456 1.10352 56.8714 3.3121 61.8407 7.17713" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M36.9931 62.3919C22.6373 59.6312 12.1465 46.9318 12.1465 32.0238C12.1465 30.9195 12.1465 29.8152 12.1465 28.7109" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M43.0678 69.0183C46.4221 69.0183 49.1414 66.2991 49.1414 62.9447C49.1414 59.5903 46.4221 56.8711 43.0678 56.8711C39.7134 56.8711 36.9941 59.5903 36.9941 62.9447C36.9941 66.2991 39.7134 69.0183 43.0678 69.0183Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M70.1224 22.6375C73.4768 22.6375 76.1961 19.9182 76.1961 16.5639C76.1961 13.2095 73.4768 10.4902 70.1224 10.4902C66.7681 10.4902 64.0488 13.2095 64.0488 16.5639C64.0488 19.9182 66.7681 22.6375 70.1224 22.6375Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M16.5639 22.6375C19.9182 22.6375 22.6375 19.9182 22.6375 16.5639C22.6375 13.2095 19.9182 10.4902 16.5639 10.4902C13.2095 10.4902 10.4902 13.2095 10.4902 16.5639C10.4902 19.9182 13.2095 22.6375 16.5639 22.6375Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </svg>
                            `,
              name: "优化医院管理",
            },
          ],
        },
        partTwo: {
          title: "产品功能介绍",
          sub: "场景化满意度问卷设计 结构化数据分析统计",
          list: [
            {
              title: "基础性服务",
              desc: "满意度管理系统支持按患者门诊和住院过程中的不同业务场景来生成个性化的满意度调查问卷，以了解每一个业务环节中患者的满意度情况，方便医院细化管理，减轻医护人员工作量。",
            },
          ],
          right_img: require("@/assets/xuwei/2B5EYGT.jpg"),
        },
      },
    };
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
};
</script>

<style>
</style>